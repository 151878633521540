





















































































































































































import Vue from 'vue';
import RegistrationFieldAgreement from '@/views/landing/register/components/registration-field-agreement.vue';
import RegistrationFieldBusinessUnit from '@/views/landing/register/components/registration-field-business-unit.vue';
import RegistrationFieldMemberId from '@/views/landing/register/components/registration-field-member-id.vue';
import RegistrationFieldProfession from '@/views/landing/register/components/registration-field-profession.vue';
import PrimaryButton from '@/views/components/button/primary-button.vue';

export default Vue.extend({
  name: 'referral-acceptance',
  components: {
    PrimaryButton,
    RegistrationFieldAgreement,
    RegistrationFieldBusinessUnit,
    RegistrationFieldMemberId,
    RegistrationFieldProfession,
  },
  data() {
    return {
      referralKey: this.$route.query.referral_key,
      loading: false,
      showDialog: false,
      valid: false,
      currentStep: 0,
      referral: {
        organization: null as string | null,
        businessUnitId: null as string | null,
        professionId: null as string | null,
        memberCodeConfiguration: {
          active: false,
          memberId: null as string | null,
          program: null as string | null,
        },
      },
      form: {
        businessUnit: {
          value: null as string | null,
          type: null as string | null,
          custom: null as string | null,
          filters: null as string[] | null,
        },
        professionId: null as string | null,
        agreement: null as string | null,
        memberCode: null as string | null,
      },
      businessUnitLabels: {
        label: null as string | null,
        question: null as string | null,
        allowOther: null as string | null,
      },
      privacyDisclaimer: false,
    };
  },
  computed: {
    user(): any {
      return this.$store.getters.user;
    },
    memberIdConfig(): object | null {
      return this.referral?.memberCodeConfiguration;
    },
    program(): string | null {
      return this.referral?.memberCodeConfiguration?.program;
    },
  },
  mounted() {
    if (!this.referralKey) {
      this.doCancel();
      return;
    }
    this.$store.dispatch('prepareReferralAcceptance', this.referralKey).then(res => {
      if (typeof res !== 'string' && (!res.status || res.status === 200)) {
        this.$store.dispatch('getBusinessUnitLabelByReferralKey', this.referralKey).then(resLabel => {
          this.businessUnitLabels.label = resLabel.label;
          this.businessUnitLabels.question = resLabel.question;
          this.businessUnitLabels.allowOther = resLabel.allowOther;

          this.form.businessUnit.value = res.businessUnitId || (res.sameOrganization ? this.user.businessUnitId : null);
          if (this.form.businessUnit.value) {
            this.form.businessUnit.type = 'predefined';
            this.form.businessUnit.custom = null;
          } else {
            this.form.businessUnit.type = 'custom';
            this.form.businessUnit.custom = res.businessUnitOther || (res.sameOrganization ? this.user.businessUnitOther : null);
          }
          this.form.professionId = res.professionId || (res.sameOrganization ? this.user.professionId : null);
          this.form.memberCode = res.memberCodeConfiguration?.memberId || (res.sameOrganization ? this.user.organizationMemberCode : null);
          this.referral = res;
          this.showDialog = true;
        });
      } else {
        this.doCancel();
      }
    });
    this.$store.dispatch('getClinicianDisclaimerByReferralKey', this.referralKey).then(res => {
      this.privacyDisclaimer = res.clinicianDisclaimer;
    });
  },
  methods: {
    doAccept() {
      this.loading = true;

      const toRegister = {
        referralKey: this.referralKey,
        professionId: this.form.professionId,
        businessUnitId: this.form.businessUnit.type === 'custom' ? null : this.form.businessUnit.value,
        businessUnitOther: this.form.businessUnit.type === 'custom' ? this.form.businessUnit.custom : null,
        businessUnitSelectionType: this.form.businessUnit.type,
        businessUnitSublevels: this.form.businessUnit.filters,
        memberCode: this.form.memberCode,
      };

      this.$store.dispatch('referralAccept', toRegister).then(res => {
        if (res.status === 204) {
          this.showDialog = false;
          // Update profile
          this.$store.dispatch('getUser').then(() => {
            this.$store.dispatch('identity/appearance').then(() => {
              this.$router.replace({ name: 'home' });
            });
          });
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    doCancel() {
      this.showDialog = false;
      this.$router.push({ name: 'home' });
    },
  },
});
