var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.referral
    ? _c(
        "v-dialog",
        {
          attrs: {
            "max-width": _vm.$vuetify.breakpoint.xsOnly ? "98%" : "800px",
            "content-class": "radius-15 referral-dialog",
            light: "",
            persistent: ""
          },
          model: {
            value: _vm.showDialog,
            callback: function($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-img", {
                attrs: {
                  contain: "",
                  src: require("@/assets/svg/banner-program-switch.svg")
                }
              }),
              _c(
                "v-carousel",
                {
                  class: {
                    mobile: _vm.$vuetify.breakpoint.smAndDown,
                    desktop: _vm.$vuetify.breakpoint.mdAndUp,
                    "pa-3": true
                  },
                  staticStyle: { "min-height": "700px", "box-shadow": "none" },
                  attrs: {
                    light: "",
                    "prev-icon": false,
                    "next-icon": false,
                    "hide-delimiters": "",
                    "hide-controls": "",
                    interval: 15000000,
                    continuous: false,
                    height: "100%",
                    cycle: false
                  },
                  model: {
                    value: _vm.currentStep,
                    callback: function($$v) {
                      _vm.currentStep = $$v
                    },
                    expression: "currentStep"
                  }
                },
                [
                  _c(
                    "v-carousel-item",
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            row: "",
                            wrap: "",
                            "justify-center": "",
                            "gap-xs-3": ""
                          }
                        },
                        [
                          _c("v-flex", { attrs: { xs11: "", md10: "" } }, [
                            _c("h3", { staticClass: "starling-h3" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "app.home.referral_acceptance.message.title"
                                  )
                                )
                              )
                            ])
                          ]),
                          _c("v-flex", { attrs: { xs11: "", md10: "" } }, [
                            _c("p", {
                              staticClass: "starling-body starling-content",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t(
                                    "app.home.referral_acceptance.message.content",
                                    { caseManager: _vm.referral.caseManager }
                                  )
                                )
                              }
                            })
                          ]),
                          _c("v-flex", { attrs: { xs11: "", md10: "" } }, [
                            _c("blockquote", {
                              staticClass: "starling-body",
                              domProps: {
                                innerHTML: _vm._s(_vm.referral.message)
                              }
                            })
                          ]),
                          _c("v-flex", { attrs: { xs11: "", md10: "" } }, [
                            _c(
                              "p",
                              { staticClass: "starling-body starling-content" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "app.home.referral_acceptance.message.cta"
                                    )
                                  )
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            row: "",
                            wrap: "",
                            "mt-3": "",
                            "pt-3": "",
                            "gap-xs-3": ""
                          }
                        },
                        [
                          _c(
                            "v-flex",
                            { staticClass: "text-center", attrs: { xs12: "" } },
                            [
                              _c(
                                "primary-button",
                                {
                                  attrs: { large: "", loading: _vm.loading },
                                  on: {
                                    click: function($event) {
                                      _vm.currentStep++
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "app.home.referral_acceptance.message.buttons.accept"
                                        )
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { staticClass: "text-center", attrs: { xs12: "" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    type: "button",
                                    large: "",
                                    flat: "",
                                    round: "",
                                    loading: _vm.loading
                                  },
                                  on: { click: _vm.doCancel }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "app.home.referral_acceptance.message.buttons.decline"
                                        )
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-carousel-item",
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          attrs: {
                            autocomplete: "off",
                            transition: "slide-y-reverse-transition"
                          },
                          model: {
                            value: _vm.valid,
                            callback: function($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid"
                          }
                        },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                row: "",
                                wrap: "",
                                "justify-center": "",
                                "gap-xs-3": ""
                              }
                            },
                            [
                              _c("v-flex", { attrs: { xs11: "", md10: "" } }, [
                                _c("h3", { staticClass: "starling-h3" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "app.home.referral_acceptance.update_profile.title"
                                      )
                                    )
                                  )
                                ])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                row: "",
                                wrap: "",
                                "justify-center": "",
                                "mt-3": "",
                                "gap-xs-3": ""
                              }
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs11: "", md10: "" } },
                                [
                                  _c(
                                    "h4",
                                    {
                                      staticClass:
                                        "starling-body font-weight-medium"
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.businessUnitLabels.question
                                              ? _vm.businessUnitLabels.question
                                              : _vm.$t(
                                                  "public.registration.profile.fields.businessUnit.title"
                                                )
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  ),
                                  _vm.showDialog
                                    ? _c("registration-field-business-unit", {
                                        attrs: {
                                          label: _vm.businessUnitLabels.label,
                                          "referral-key": _vm.referralKey,
                                          "enable-not-listed":
                                            _vm.businessUnitLabels.allowOther
                                        },
                                        model: {
                                          value: _vm.form.businessUnit,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "businessUnit",
                                              $$v
                                            )
                                          },
                                          expression: "form.businessUnit"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs11: "", md10: "" } },
                                [
                                  _c(
                                    "h4",
                                    {
                                      staticClass:
                                        "starling-body font-weight-medium"
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "public.registration.profile.fields.profession.title"
                                            )
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  ),
                                  _c("registration-field-profession", {
                                    attrs: { "referral-key": _vm.referralKey },
                                    model: {
                                      value: _vm.form.professionId,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "professionId", $$v)
                                      },
                                      expression: "form.professionId"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs11: "", md10: "" } },
                                [
                                  _vm.memberIdConfig &&
                                  _vm.memberIdConfig.active
                                    ? _c("registration-field-member-id", {
                                        attrs: {
                                          configuration: _vm.memberIdConfig
                                        },
                                        model: {
                                          value: _vm.form.memberCode,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "memberCode",
                                              $$v
                                            )
                                          },
                                          expression: "form.memberCode"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                row: "",
                                wrap: "",
                                "justify-center": "",
                                "mt-3": "",
                                "gap-xs-3": ""
                              }
                            },
                            [
                              _c("v-flex", { attrs: { xs11: "", md10: "" } }, [
                                _c("h3", { staticClass: "starling-h3" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t(
                                          "public.registration.account.privacy_subtitle"
                                        )
                                      ) +
                                      "\n              "
                                  )
                                ])
                              ]),
                              _vm.program
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs11: "", md10: "" } },
                                    [
                                      _vm.privacyDisclaimer
                                        ? _c(
                                            "p",
                                            {
                                              staticClass:
                                                "starling-body starling-content"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "public.registration.account." +
                                                        _vm.program +
                                                        ".privacy_clinician"
                                                    )[0]
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        : _c(
                                            "p",
                                            {
                                              staticClass:
                                                "starling-body starling-content"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "public.registration.account." +
                                                        _vm.program +
                                                        ".privacy"
                                                    )[0]
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.program
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs11: "", md10: "" } },
                                    [
                                      _vm.privacyDisclaimer
                                        ? _c(
                                            "p",
                                            {
                                              staticClass:
                                                "starling-body starling-content"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "public.registration.account." +
                                                        _vm.program +
                                                        ".privacy_clinician"
                                                    )[1]
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        : _c(
                                            "p",
                                            {
                                              staticClass:
                                                "starling-body starling-content"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "public.registration.account." +
                                                        _vm.program +
                                                        ".privacy"
                                                    )[1]
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "v-flex",
                                { attrs: { xs11: "", md10: "" } },
                                [
                                  _c("registration-field-agreement", {
                                    attrs: { "referral-key": _vm.referralKey },
                                    model: {
                                      value: _vm.form.agreement,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "agreement", $$v)
                                      },
                                      expression: "form.agreement"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.valid && _vm.form.agreement
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs11: "", md10: "" } },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "starling-body starling-content"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$t(
                                                  "public.registration.profile.finalisation_content"
                                                )[0]
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "starling-body pt-3 starling-content"
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "public.registration.profile.finalisation_content"
                                                )[1]
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                row: "",
                                wrap: "",
                                "mt-3": "",
                                "pt-3": "",
                                "gap-xs-3": ""
                              }
                            },
                            [
                              _c(
                                "v-flex",
                                {
                                  staticClass: "text-center",
                                  attrs: { xs12: "" }
                                },
                                [
                                  _c(
                                    "primary-button",
                                    {
                                      attrs: {
                                        large: "",
                                        disabled:
                                          !_vm.valid ||
                                          _vm.loading ||
                                          !_vm.form.agreement,
                                        loading: _vm.loading
                                      },
                                      on: { click: _vm.doAccept }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "app.home.referral_acceptance.update_profile.buttons.accept"
                                            )
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "text-center",
                                  attrs: { xs12: "" }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        type: "button",
                                        large: "",
                                        flat: "",
                                        round: "",
                                        loading: _vm.loading
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.currentStep--
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "app.home.referral_acceptance.update_profile.buttons.back"
                                            )
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }